import cx from 'classnames';
import React, { KeyboardEvent, PureComponent } from 'react';

import styles from './UIInput.module.css';

interface IProps {
  value: string;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  className?: string;
  placeholder?: string;
  name?: string;
  hasError?: string[] | string;
  mask?: any;
  disabled?: boolean;
  type?: string;
  autoComplete?: 'on' | 'off';
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
  maxLength?: number;
  id?: string;
}

export class UIInput extends PureComponent<IProps> {
  public static defaultProps = {
    type: 'text',
  };

  public handeledSave = (value: string): void => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  public handeledChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    this.handeledSave(value);
  };

  public handeledBlur = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { onBlur } = this.props;
    if (onBlur) {
      const value = e.target.value.trim();

      onBlur(value);
    }
  };

  public render() {
    const { className, value, onChange, placeholder, name, disabled, type, autoComplete, onKeyPress, maxLength, id } =
      this.props;
    const isEdit = typeof onChange === 'function';
    const classes = cx(styles.input, className);
    return (
      <>
        <input
          id={id}
          className={classes}
          autoComplete={autoComplete}
          value={value}
          onChange={this.handeledChange}
          onBlur={this.handeledBlur}
          readOnly={!isEdit}
          placeholder={placeholder}
          type={type}
          name={name}
          disabled={disabled}
          onKeyPress={onKeyPress}
          maxLength={maxLength}
        />
      </>
    );
  }
}
