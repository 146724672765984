import cx from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useDebounce } from 'src/hooks/useDebounce';
import { EProjectStatus, IProject, TProjectObject } from 'src/types/LandingMapTypes';
import Row from '../components/Row';
import styles from '../LandingMapPopup.module.css';
import ProgressWithDate from './ProgressWithDate';
import ShowObjectsButton from './ShowObjectsButton';

const stageStatus = {
  'NEW': 'NEW',
  'OPEN': 'OPEN',
  'POSTPONED': 'POSTPONED',
  'REJECTED': 'REJECTED',
  'IN_WORK': 'В работе',
  'COMPLETED': 'Завершен',
  'CLOSED': 'Закрыт',
} as const;

export const ProjectHeaderContent: FC<
  IProject & { objectId?: number; hasObjects?: boolean; setObjects?: (objs: TProjectObject[]) => void }
> = ({ objects, hasObjects, setObjects, state, name, objectId }) => {
  const [isShow, setIsShow] = useState(false);
  const debouncedShow = useDebounce(isShow, 250);
  const objectLength = objects?.length;
  const isMoreThanOne = objects?.length > 1;

  useEffect(() => {
    if (isMoreThanOne && debouncedShow) {
      setObjects?.(objects);
    }

    if (hasObjects && !debouncedShow) {
      setObjects?.([]);
    }
  }, [setObjects, isMoreThanOne, debouncedShow, hasObjects]);

  return (
    <>
      <div className={cx(styles.contentPopupTitle, styles.row)}>{name}</div>
      <div className={cx(styles.contentPopupStatus, styles.row)}>
        <span
          className={cx(
            styles.contentPopupStatusIndicator,
            state == EProjectStatus.IN_WORK
              ? styles.contentPopupStatusIndicatorInProcess
              : state == EProjectStatus.COMPLETED
              ? styles.contentPopupStatusIndicatorCompleted
              : styles.contentPopupStatusIndicatorClose,
          )}
        />
        {stageStatus[state]}
      </div>

      {isMoreThanOne && <div className={cx(styles.bold, styles.row)}>{`Объекты (${objectLength}) :`}</div>}

      <div className={cx(styles.row)}>
        {objects.map((object) => (
          <div key={object.id}>- {object.shortName}</div>
        ))}
      </div>

      {isMoreThanOne && (
        <div className={cx(styles.row)}>
          <ShowObjectsButton setIsShow={setIsShow} isShow={debouncedShow} />{' '}
        </div>
      )}
    </>
  );
};

export const ProhectBodyContent: FC<IProject> = (item) => (
  <>
    <Row prop="Тип:" value={item.type} />
    <Row prop="Описание:" value={item.explanation} />
    <Row prop="Ответственная организация:" value={item.opa} />
    <Row prop="Ответственный исполнитель:" value={item.executor} />
    <Row prop="Плановая дата закрытия проекта:" value={<ProgressWithDate {...item} />} />
    <Row prop="Обращений включенных в проект:" value={item.appealNum} />
  </>
);
